import React, { useContext } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";
// import { useImageSize } from "react-image-size";
// import useImageSize from "path-to-useImageSize";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Image,
  Modal,
} from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);

  const [formData, setFormData] = useState({
    name: "",
    image: "",
    imageUrl: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = async (e) => {
    if (e.target.name === "image") {
      const data = new FormData();
      data.append("image", e.target.files[0]);
      data.append("width", 198);
      data.append("height", 118);
      const checkimage = await postData("/image", data);

      if (!checkimage?.success) {
        await setError({ image: checkimage?.message });
      } else {
        const newFormData = {
          ...formData,
          image: e.target.files[0],
          imageUrl: URL.createObjectURL(e.target.files[0]),
        };
        await setError({ image: null });
        await setFormData(newFormData);
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const [error, setError] = useState({
    image: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".industryclass",
    });

    if (validationErrors && error.image == null) {
      try {
        const data = new FormData();
        data.append("name", formData.name);
        data.append("image", formData.image);
        const response = await postData("/masters/industry", data);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Industry</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form onSubmit={handleSubmit} role="form" className="industryclass">
                            <Row>
                              <Col lg={8} md={10} className="mx-auto Add-content">
                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Industry
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Industry"
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            <span className="error-message"></span>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Image
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="file"
                                                name="image"
                                                onChange={handleChange}
                                                placeholder="Image"
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            <span className="error-message"></span>
                                          </Form.Group>
                                      
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Image Preview
                                        </Form.Label>
                                        <Col sm={6}>
                                          {formData.imageUrl && (
                                            <Col
                                              sm={3}
                                              className="image-preview-col"
                                            >
                                              <div className="image-preview-container">
                                                <img
                                                  src={formData.imageUrl}
                                                  alt="Preview"
                                                  className="image-preview-container"
                                                  style={{
                                                    width: "120px",
                                                    height: "120px",
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Industry
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="industryclass">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Industry</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Industry"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="file"
                          name="image"
                          onChange={handleChange}
                          accept=".jpg, .jpeg, .png"
                          placeholder="Image"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                      {error?.image && (
                        <p className="m-0" style={{ color: "red" }}>
                          {error?.image}
                        </p>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <Row className="">
                      <Form.Label>Image Preview</Form.Label>

                      {formData.imageUrl && (
                        <Col sm={3} className="image-preview-col">
                          <div className="image-preview-container">
                            <img
                              src={formData.imageUrl}
                              alt="Preview"
                              className="image-preview-container"
                              style={{
                                width: "120px",
                                height: "120px",
                              }}
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-center">
                <Link>
                  <CancelButton
                    name={"cancel"}
                    handleClose={props.handleClose}
                  />
                </Link>
                <SaveButton name={"save"} handleSubmit={handleSubmit} />
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
