import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./SettingContent.css";
import Citytable from "../City/Tables";
import Statetable from "../State/Tables";
import Countrytable from "../Country/Tables";
import JobTypetable from "../JobType/Tables";
import JobTitleTable from "../JobTitle/Tables";
import IndustryTable from "../Industry/Tables";
import SectorTable from "../Sector/Tables";
import CareerLevelTable from "../CareerLevel/Tables";
import QualificationTable from "../Qualification/Tables";
import EducationTable from "../Education/Table";
import LanguageTable from "../Language/Table";
import BlogTypeTable from "../BlogType/Tables";
import BlogTable from "../Blog/Tables";
import ExpereinceInYearsTable from "../ExpericeInYears/Tables";
import { useLocation } from "react-router-dom";
import ShiftTable from "../Shift/Table";
import DegreeTypetable from "../DegreeType/Table";
import Degreetable from "../Degree/Tables";
import UniversityTypetable from "../UniversityType/Table";
import UniverSitytable from "../University/Table";
import SkilltypeTable from "../SkillType/Table";
import SkillnameTable from "../Skills/Tables";
import ClientreviewTable from "../ClientReview/Tables";
import CompanyTable from "../Company/Table";
import SocialLinktable from "../SocialLink/Tables";
import ProjectTypetable from "../ProjectType/Table";
import RoleTypetable from "../Role_type/Table";
import ROlesubtypeTable from "../RoleSubtype/Table";
import RoleTable from "../Roles/Table";
import RoundTable from "../Round/Table";
import AuthorityTable from "../Authority/Table";
import LeagueTable from "../League/Table";
import DesciplineTable from "../Descipline/Table";
import CategoryDesciplineTable from "../CategoryDescipline/Table";
import SkillLeaguetable from "../SkillLeague/Table";
import SkillMastertable from "../SkillsMaster/Table";
import EducationTypeTable from "../EducationType/Tables";
import AchievementTable from "../Achievement/Table";
import ExperienceType from "../ExperienceType/Table";
import LevelTable from "../Level/Tables";
import NationalityTable from "../Nationality/Tables";
import TalentTable from "../Talent/Table";
import JobNature from "../JobNature/Tables";
import Location from "../Location/Tables";
import Category from "../Category/Tables";
import Type from "../Type/Tables";
import CertificateFor from "../CertificateFor/Tables";
import Ourpartners from "../Ourpartners/Tables";
import OurAbout from "../OurAbout/Table";

const SettingContent = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabItems = [
    { key: 1, name: "Country", link: "masters/country" },
    { key: 2, name: "State", link: "masters/state" },
    { key: 3, name: "City", link: "masters/city" },
    { key: 4, name: "Job Type", link: "masters/job_type" },
    { key: 5, name: "Job Title", link: "masters/job_title" },
    { key: 6, name: "Industry", link: "masters/industry" },
    { key: 7, name: "Sector", link: "masters/sector" },
    { key: 8, name: "Career Level", link: "masters/career_level" },
    // { key: 9, name: "Qualification", link: "masters/qualification" },
    // { key: 10, name: "Education Type", link: "masters/education_type" },
    {
      key: 11,
      name: "Experince in Years",
      link: "masters/experience_in_year",
    },
    { key: 12, name: "Blog Type", link: "masters/blog_type" },
    { key: 13, name: "Blog", link: "masters/blog" },
    { key: 14, name: "Language", link: "masters/language" },
    { key: 15, name: "Shift", link: "masters/shift" },
    { key: 16, name: "Degree Type", link: "masters/degree_type" },
    { key: 17, name: "Degree", link: "masters/degree" },
    { key: 18, name: "University Type", link: "masters/university_type" },
    { key: 19, name: "University", link: "masters/university" },
    { key: 20, name: "Skill Type", link: "masters/skill_type" },
    { key: 21, name: "Skill Name", link: "masters/skills" },
    { key: 22, name: "Client Review", link: "masters/client_review" },
    { key: 23, name: "Company List", link: "masters/company" },
    // { key: 24, name: "Social Link", link: "masters/social_links" },
    // { key: 25, name: "Project Type", link: "masters/project_type" },
    { key: 26, name: " Role Type", link: "masters/role_type" },
    { key: 27, name: "Role Subtype", link: "masters/role_subtype" },
    { key: 28, name: "Roles", link: "masters/roles" },
    { key: 29, name: "Round", link: "masters/round" },
    // { key: 30, name: "Authority Name", link: "masters/authority" },
    { key: 31, name: "League", link: "masters/league" },
    {
      key: 36,
      name: "Category Descipline",
      link: "masters/category_descipline",
    },
    {
      key: 37,
      name: "Achievement",
      link: "masters/category_descipline",
    },
    // {
    //   key: 38,
    //   name: "Experience Type",
    //   link: "masters/experience_type",
    // },
    {
      key: 39,
      name: "Level",
      link: "masters/level",
    },
    {
      key: 40,
      name: "Nationality",
      link: "masters/nationality",
    },
    {
      key: 41,
      name: "Talent",
      link: "masters/talent",
    },

    {
      key: 42,
      name: "Job Nature",
      link: "masters/job_nature",
    },

    // {
    //   key: 43,
    //   name: "Location",
    //   link: "masters/location",
    // },
    // {
    //   key: 44,
    //   name: "Category",
    //   link: "masters/category",
    // },
    {
      key: 45,
      name: "Project Type",
      link: "masters/type",
    },
    {
      key: 46,
      name: "Certificate For",
      link: "masters/certificate_for",
    },
    {
      key: 47,
      name: "Our Partners",
      link: "masters/ourpartners",
    },

    {
      key: 48,
      name: "Our About",
      link: "masters/our_about",
    },
    { key: 32, name: "Descipline", link: "masters/descipline" },
    // { key: 33, name: "Skill League", link: "masters/skill_league" },
    // { key: 34, name: "Skill Master", link: "masters/skills_master" },
    // { key: 35, name: "Education Master", link: "masters/education" },
    // { key: 36, name: "Country Code", link: "masters/country-code" },
  ];

  return (
    <>
      <Header title={tabItems[active]?.name} link={"/masters/country"} />

      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Row>
          <Col xl={2} lg={3} md={4}>
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={9} md={8}>
            <Tab.Content>
              <Tab.Pane eventKey="1">
                <Countrytable />
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <Statetable />
              </Tab.Pane>
              <Tab.Pane eventKey="3">
                {" "}
                <Citytable />{" "}
              </Tab.Pane>
              <Tab.Pane eventKey="4">
                <JobTypetable />
              </Tab.Pane>
              <Tab.Pane eventKey="5">
                <JobTitleTable />
              </Tab.Pane>
              <Tab.Pane eventKey="6">
                <IndustryTable />
              </Tab.Pane>
              <Tab.Pane eventKey="7">
                <SectorTable />
              </Tab.Pane>
              <Tab.Pane eventKey="8">
                <CareerLevelTable />
              </Tab.Pane>
              <Tab.Pane eventKey="9">
                <QualificationTable />
              </Tab.Pane>
              <Tab.Pane eventKey="10">
                <EducationTypeTable />
              </Tab.Pane>
              <Tab.Pane eventKey="11">
                <ExpereinceInYearsTable />
              </Tab.Pane>
              <Tab.Pane eventKey="12">
                <BlogTypeTable />
              </Tab.Pane>
              <Tab.Pane eventKey="13">
                <BlogTable />
              </Tab.Pane>
              <Tab.Pane eventKey="14">
                <LanguageTable />
              </Tab.Pane>
              <Tab.Pane eventKey="15">
                <ShiftTable />
              </Tab.Pane>
              <Tab.Pane eventKey="16">
                <DegreeTypetable />
              </Tab.Pane>
              <Tab.Pane eventKey="17">
                <Degreetable />
              </Tab.Pane>
              <Tab.Pane eventKey="18">
                <UniversityTypetable />
              </Tab.Pane>
              <Tab.Pane eventKey="19">
                <UniverSitytable />
              </Tab.Pane>
              <Tab.Pane eventKey="20">
                <SkilltypeTable />
              </Tab.Pane>
              <Tab.Pane eventKey="21">
                <SkillnameTable />
              </Tab.Pane>
              <Tab.Pane eventKey="22">
                <ClientreviewTable />
              </Tab.Pane>
              <Tab.Pane eventKey="23">
                <CompanyTable />
              </Tab.Pane>
              <Tab.Pane eventKey="24">
                <SocialLinktable />
              </Tab.Pane>
              {/* <Tab.Pane eventKey="25">
                <ProjectTypetable />
              </Tab.Pane> */}
              <Tab.Pane eventKey="26">
                <RoleTypetable />
              </Tab.Pane>
              <Tab.Pane eventKey="27">
                <ROlesubtypeTable />
              </Tab.Pane>
              <Tab.Pane eventKey="28">
                <RoleTable />
              </Tab.Pane>
              <Tab.Pane eventKey="29">
                <RoundTable />
              </Tab.Pane>
              <Tab.Pane eventKey="30">
                <AuthorityTable />
              </Tab.Pane>
              <Tab.Pane eventKey="31">
                <LeagueTable />
              </Tab.Pane>

              <Tab.Pane eventKey="36">
                <CategoryDesciplineTable />
              </Tab.Pane>

              <Tab.Pane eventKey="37">
                <AchievementTable />
              </Tab.Pane>

              <Tab.Pane eventKey="32">
                <DesciplineTable />
              </Tab.Pane>
              <Tab.Pane eventKey="33">
                <SkillLeaguetable />
              </Tab.Pane>
              <Tab.Pane eventKey="34">
                <SkillMastertable />
              </Tab.Pane>
              <Tab.Pane eventKey="35">
                <EducationTable />
              </Tab.Pane>
              <Tab.Pane eventKey="38">
                <ExperienceType />
              </Tab.Pane>

              <Tab.Pane eventKey="39">
                <LevelTable />
              </Tab.Pane>

              <Tab.Pane eventKey="40">
                <NationalityTable />
              </Tab.Pane>

              <Tab.Pane eventKey="41">
                <TalentTable />
              </Tab.Pane>
              <Tab.Pane eventKey="42">
                <JobNature />
              </Tab.Pane>
              <Tab.Pane eventKey="43">
                <Location />
              </Tab.Pane>
              <Tab.Pane eventKey="44">
                <Category />
              </Tab.Pane>
              <Tab.Pane eventKey="45">
                <Type />
              </Tab.Pane>
              <Tab.Pane eventKey="46">
                <CertificateFor />
              </Tab.Pane>
              <Tab.Pane eventKey="47">
                <Ourpartners />
              </Tab.Pane>

              <Tab.Pane eventKey="48">
                <OurAbout />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default SettingContent;
