import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

// Talent Pool
import TalentPoolCategory from "./Components/TalentPool/TalentPoolCategory/Tables";
import TalentPoolSubcategory from "./Components/TalentPool/TalentPoolSubcategory/Tables";
import TalentPoolPlan from "./Components/TalentPool/TalentPoolPlan/Tables";

// Recruiters
import PoolSubscriber from "./Components/PoolSubscriber/Subscriber_list/Table";

// Customer Management
import RecruitersList from "./Components/CustomerManagement/RecruitersList/Tables";
import ContactUsList from "./Components/CustomerManagement/ContactUSList/Tables";
import JobSeekerList from "./Components/CustomerManagement/JobSeekerList/Table";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";
// import Home from "./Components/Dashboard/Home/Home.js";

const App = () => {
  const location = useLocation();
  const { isSidebarOpen } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className="row sidebar-x-scroll">
          <div
            className={`${
              isSidebarOpen
                ? "col-xl-2 col-lg-2 col-md-3"
                : "scale-in-hor-left col-xl-1 col-lg-1 col-md-1"
            }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${
              isSidebarOpen
                ? "col-xl-10 col-md-9  col-lg-10"
                : "scale-in-hor-right  col-xl-11 col-md-11"
            }  ps-0`}
          >
            <div className="allRoutesMain">
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/advanceDashboard"
                  element={<AdvanceDashboard />}
                />

                <Route path="/settingContent" element={<SettingContent />} />

                {/* Masters End */}

                <Route
                  path="/talent_pool/talent_pool_category"
                  element={<TalentPoolCategory />}
                />

                <Route
                  path="/talent_pool/talent_pool_subcategory"
                  element={<TalentPoolSubcategory />}
                />

                <Route
                  path="/talent_pool/talent_pool_plan"
                  element={<TalentPoolPlan />}
                />

                <Route
                  path="/recruiters/recruiters_list"
                  element={<RecruitersList />}
                />

                <Route
                  path="/recruiters/contact_us_list"
                  element={<ContactUsList />}
                />

                <Route
                  path="/recruiters/pool_subscriber"
                  element={<PoolSubscriber />}
                />

                <Route
                  path="/jobseeker/jobseeker_list"
                  element={<JobSeekerList />}
                />
              </Routes>
            </div>
            {/* <Home /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
