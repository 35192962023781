
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);




const AddOffCanvance = (props) => {
    const { postData, getData } = useContext(Context);
    const [category, setCategory] = useState({});
    const [formData, setFormData] = useState({
      name: "",
      talent_pool_category_id: "",
    });
  
    const [showModal, setShowModal] = useState({
        code: 0,
        message: "",
      });
  
    const handleChange = (e) => {
      if (e?.name == "talent_pool_category_id") {
        setFormData({ ...formData, [e.name]: e });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const validationErrors = await Validation.FormValidation({
          classname: ".talentpoolsubcategoryclass",
        });
    
        const validationErrorsSelect = Validation.SelectValidation({
          classname: ".talentpoolsubcategoryclass",
        });
    
        if (validationErrors && validationErrorsSelect) {
          try  {
          const response = await postData(
            "/talent_pool/talent_pool_subcategory",
            formData
          );
  
          if (response.success) {
            setShowModal({ code: response.code, message: response.message });
            setTimeout(() => {
              setShowModal(0);
              props.handleClose();
            }, 1000);
          } else {
            setShowModal({ code: response.code, message: response.message });
            setTimeout(() => {
              setShowModal(0);
              props.handleClose();
            }, 1000);
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    };
  
    const errorStyle = {
      color: "red",
      marginLeft: "5px",
    };
  
    const getCategoryData = async () => {
      const res = await getData("/getalltalentpoolcategory");
  
      const option = [];
      if (res.success) {
        res?.data?.map((data) => {
          option.push({
            value: data.id,
            name: "talent_pool_category_id",
            label: data.name,
          });
        });
      }
      setCategory(option);
    };
  
    useEffect(() => {
      getCategoryData();
    }, []);
  
    return (
      <>
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Talent Pool Subcategory</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Add Talent Pool Subcategory</Card.Title>
                <hr />
                <Row>
                  <Col md={12}>
                    <Row className="">
                      <Col xxl={12} xl={12} md={10}>
                        <Form onSubmit={handleSubmit} role="form" className="talentpoolsubcategoryclass">
                          <Row>
                            <Col md={6}>
                              <div className="main-form-section mt-5">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                    Talent Pool Category
                                  </Form.Label>
                                  <Col sm={6}>
                                    <Form.Group>
                                      <Select
                                        name="talent_pool_category_id"
                                        className="input-mandatory" // Add the Bootstrap class
                                        value={formData?.talent_pool_category_id}
                                        options={category}
                                        onChange={handleChange}
                                      />
                                    <span className="error-message"></span>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="main-form-section mt-5">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                    Talent Pool Subcategory 
                                  </Form.Label>
                                  <Col sm={6}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          value={formData.name}
                                          onChange={handleChange}
                                          placeholder="Talent Pool Subcategory "
                                          id="inputEmail3"
                                          className="input-mandatory"
                                        />
                                      </InputGroup>
                                      <span className="error-message"></span>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
  
                          <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>
  
        <ModalSave
          message={showModal.message}
          showErrorModal={showModal.code ? true : false}
        />
      </>
    );
  };
  
  export default AddOffCanvance;
  