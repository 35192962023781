import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import "./table.css";
import colunms from "../../../Components/assets/icons/LINES.png";
import plus from "../../../Components/assets/icons/a1.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import toast, { Toaster } from "react-hot-toast";
import { type } from "@testing-library/user-event/dist/type";

library.add(fas);

const TableOffCanvance = (props) => {
  const {
    getData,

    getDownloadDataExcel,
    Per_Page_Dropdown,
    IMG_URL,
  } = useContext(Context);
  const TabListStyles = {
    listStyleType: "none",
    padding: 0,
    marginTop: 20,
    display: "flex",
  };

  const openPdfInNewTab = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>
                <h3>Job Seeker Details </h3>
              </Card.Title>
              <Container>
                <Tabs defaultIndex={0}>
                  <TabList style={TabListStyles}>
                    <Tab className="me-5"> Details</Tab>
                    <Tab className="me-5">Educations</Tab>
                    <Tab className="me-5">Certificate</Tab>
                    <Tab className="me-5">Job Experience</Tab>
                    <Tab className="me-5">Job Freelancer</Tab>
                    <Tab className="me-5">Project</Tab>
                  </TabList>
                  <TabPanel>
                    <Row>
                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>First Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={props?.data?.first_name}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={props?.data?.last_name}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>Email</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={props?.data?.email}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>Gender</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={props?.data?.users_detail?.gender}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>Contact No</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={props?.data?.contact_no}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>Alternative No</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={
                                    props?.data?.users_detail?.alternative_no
                                  }
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>City </Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={props?.data?.users_detail?.city?.name}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={
                                    props?.data?.users_detail?.nationality?.name
                                  }
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>DOB</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={props?.data?.users_detail?.dob}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={9}>
                        <div className="main-form-section mt-4">
                          <Row className="justify-content-center">
                            <Form.Label>Current Address</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={
                                    props?.data?.users_detail?.current_address
                                  }
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </TabPanel>

                  <TabPanel>
                    {props?.data?.jobseeker_educations?.length ? (
                      props?.data?.jobseeker_educations.map((data, index) => (
                        <>
                          <Row key={index}>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>University</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.university?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Degree Earned</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.degree?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Degree Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.degree_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>GPA or Grades</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.type}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>GPA or Grades</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.grades}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Certificate Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.certificate_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Start Date </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.start_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>End Date </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.end_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label></Form.Label>
                                  <Form.Group>
                                    <object
                                      data={IMG_URL + data?.media}
                                      type="application/pdf"
                                      width="100"
                                      height="100"
                                    >
                                      <p>
                                        browser not supported :{" "}
                                        <a
                                          href={IMG_URL + data?.media}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Download PDF
                                        </a>
                                      </p>
                                    </object>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={12}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Area of Study </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        as="textarea"
                                        rows={
                                          data
                                            ?.jobseeker_education_areaof_stuadies
                                            ?.length / 2
                                        }
                                        value={data?.jobseeker_education_areaof_stuadies?.map(
                                          (d) => d.descipline?.name
                                        )}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <hr></hr>
                        </>
                      ))
                    ) : (
                      <>{/* <h4 className="mt-5">Data Not Added</h4> */}</>
                    )}
                  </TabPanel>

                  <TabPanel>
                    {props?.data?.jobseeker_certificates?.length ? (
                      props?.data?.jobseeker_certificates.map((data, index) => (
                        <>
                          <Row key={index}>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Title </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.title}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Issuing Organization</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.issuing_org}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Certificate For</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.certificate_for?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Start Date </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.start_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>End Date </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.end_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Main Topics</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.main_topics}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Achievemente</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.achievement?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label></Form.Label>
                                  <Form.Group>
                                    <object
                                      data={IMG_URL + data?.evidance}
                                      type="application/pdf"
                                      width="100"
                                      height="100"
                                    >
                                      <p>
                                        browser not support.{" "}
                                        <a
                                          href={IMG_URL + data?.evidance}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Download PDF
                                        </a>
                                      </p>
                                    </object>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={12}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Skills</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        as="textarea"
                                        rows={
                                          data
                                            ?.jobseeker_education_areaof_stuadies
                                            ?.length / 2
                                        }
                                        value={data?.jobseeker_certificate_skills?.map(
                                          (skill) => skill?.skill?.name
                                        )}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <hr></hr>
                        </>
                      ))
                    ) : (
                      <>{/* <h4 className="mt-5">Data Not Added</h4> */}</>
                    )}
                  </TabPanel>

                  <TabPanel>
                    <Tabs defaultIndex={0}>
                      <TabList style={TabListStyles}>
                        <Tab className="me-5 active">Current Organization</Tab>
                        <Tab className="me-5">Previous Organization</Tab>
                      </TabList>

                      <TabPanel>
                        {props?.data?.jobseeker_employements?.map(
                          (data, index) => (
                            <>
                              {data?.org_type === 0 ? (
                                <>
                                  <Row key={index}>
                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Job Role</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.role?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Responsibilities
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.responsibilities}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Company Name</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.company?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Contact Person
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.contact_person}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Contact Person Email
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={
                                                  data?.contact_person_email
                                                }
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Time Period(from)
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.start_date}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Time Period(to)
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.end_date}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Job Nature</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.job_nature?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Job Location</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.location?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Achievemente</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.achievement?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={8}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Skills Used</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.jobseeker_employement_skills?.map(
                                                  (skill) => skill?.skill?.name
                                                )}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr></hr>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )
                        )}
                      </TabPanel>

                      <TabPanel>
                        {props?.data?.jobseeker_employements?.map(
                          (data, index) => (
                            <>
                              {data?.org_type === 1 ? (
                                <>
                                  <Row key={index}>
                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Job Role</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.role?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Responsibilities
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.responsibilities}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Company Name</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.company?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Contact Person
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.contact_person}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Contact Person Email
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={
                                                  data?.contact_person_email
                                                }
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Time Period(from)
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.start_date}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Time Period(to)
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.end_date}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Job Nature</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.job_nature?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Job Location</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.location?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Achievemente</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.achievement?.name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={8}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>Skills Used</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={data?.jobseeker_employement_skills?.map(
                                                  (skill) => skill?.skill?.name
                                                )}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr></hr>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )
                        )}
                      </TabPanel>
                    </Tabs>
                  </TabPanel>

                  <TabPanel>
                    {props?.data?.jobseeker_freelancers?.length ? (
                      props?.data?.jobseeker_freelancers.map((data, index) => (
                        <>
                          <Row key={index}>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Client name </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.client_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Title</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.project_title}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Your Role</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data.your_role}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Link</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.project_link}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Start Date </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.start_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>End Date </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.end_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Outcome</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.project_outcome}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Description</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.description}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Media</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <div className="col-lg-3 col-md-3">
                                        {data.media && (
                                          <div>
                                            <button
                                              type="button"
                                              className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                              title="View PDF"
                                              onClick={() =>
                                                openPdfInNewTab(data.media)
                                              }
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  display: "flex",
                                                  height: "100%",
                                                }}
                                                icon={faEye}
                                                className="viewEye"
                                              />
                                            </button>
                                            <div
                                              className="role-holder"
                                              onClick={() =>
                                                openPdfInNewTab(data.media)
                                              }
                                            >
                                              <object
                                                data={IMG_URL + data.media}
                                                type="application/pdf"
                                                width="100%"
                                                height="500px" // Adjust the height as per your requirement
                                              >
                                                <p>
                                                  Browser Not Supported{" "}
                                                  <a
                                                    href={IMG_URL + data.media}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    Download PDF
                                                  </a>
                                                </p>
                                              </object>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <hr></hr>
                        </>
                      ))
                    ) : (
                      <>{/* <h4 className="mt-5">Data Not Added</h4> */}</>
                    )}
                  </TabPanel>

                  <TabPanel>
                    {props?.data?.jobseeker_projects?.length ? (
                      props?.data?.jobseeker_projects.map((data, index) => (
                        <>
                          <Row key={index}>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.type?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Organization</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.organization}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Title</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.title}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Role</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.role}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Url</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.url}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Publications</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.publication}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Description</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.description}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Time Period(from)</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.start_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Time Period(to)</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.end_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Mentor Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.mentor_email}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Mentor/Guide Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.mentor}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Category</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.category?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Achievemente</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.achievement?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={8}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Skills Used</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.jobseeker_projects_skills?.map(
                                          (skill) => skill?.skill?.name
                                        )}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <hr></hr>
                        </>
                      ))
                    ) : (
                      <>{/* <h4 className="mt-5">Data Not Added</h4> */}</>
                    )}
                  </TabPanel>

                  <TabPanel>
                    {props?.data?.jobseeker_projects?.length ? (
                      props?.data?.jobseeker_projects.map((data, index) => (
                        <>
                          <Row key={index}>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.type?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Organization</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.organization}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Title</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.title}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Role</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.role}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Project Url</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.url}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Publications</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.publication}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Description</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.description}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Time Period(from)</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.start_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Time Period(to)</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.end_date}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Mentor Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.mentor_email}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Mentor/Guide Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.mentor}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Category</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.category?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Achievemente</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.achievement?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={8}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Skills Used</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.jobseeker_projects_skills?.map(
                                          (skill) => skill?.skill?.name
                                        )}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <hr></hr>
                        </>
                      ))
                    ) : (
                      <>{/* <h4 className="mt-5">Data Not Added</h4> */}</>
                    )}
                  </TabPanel>
                </Tabs>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const Tables = () => {
  const { getData, IMG_URL, getDownloadDataExcel, Per_Page_Dropdown } =
    useContext(Context);

  const [data, setData] = useState([]);
  const [singledata, setSingledata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [option, setOption] = useState();
  const getDataAll = async () => {
    const response = await getData(
      `/jobseeker_list?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [perPage, reset]);

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const [show, setshow] = useState(0);

  const handleClose3 = () => setshow(0);
  const handleshow = async (data) => {
    await setSingledata(data);
    await setshow(1);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,

    col12: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/JobSeeker_list/download",
          selectAllChecked,
          "Job Seeker List"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Job Seeker List"} link={"/jobseeker/jobseeker_list"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex">
                    <div className="add me-3"></div>

                    <div className="add me-1">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <img src={colunms} className="columns " alt="" />{" "}
                          Column Selection{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>
                        <ul className="dropdown-menu">
                          {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col0")}
                              href="#"
                            >
                              Check Boxs
                              {visible?.col0 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col1")}
                              href="#"
                            >
                              Sr. No.
                              {visible?.col1 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col2")}
                              href="#"
                            >
                              First Name
                              {visible?.col2 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Last Name
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Email
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col5")}
                              href="#"
                            >
                              Contact No
                              {visible?.col5 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col12")}
                              href="#"
                            >
                              View
                              {visible?.col12 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* <div className="add">
                      <Button
                        variant="success"
                        className="btn btn-view"
                        onClick={HandleDownload}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon="fa-solid fa-download"
                          className="me-2"
                        />
                        Download
                      </Button>
                    </div> */}
                  </div>
                  <div className="row mt-4">
                    <div className="  col-lg-4 col-md-4 col-12">
                      <div className="d-flex">
                        <div className="show me-2">
                          <p className="show mt-1">Show</p>
                        </div>
                        <div className="number me-2">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setperPage(e.target.value)}
                          >
                            {option?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="entries">
                          <p className="show mt-1">entries</p>
                        </div>
                      </div>
                    </div>
                    <div className="  col-lg-8  col-md-8 col-12">
                      <div className="d-flex justify-content-end">
                        <div className="sowing me-2">
                          <p className="show mt-2">{`Showing ${Math.min(
                            (currentPage - 1) * perPage + 1
                          )} to ${Math.min(
                            currentPage * perPage,
                            data?.data?.totalEntries
                          )} of ${data?.data?.totalEntries} entries`}</p>
                        </div>
                        <div className="num me-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contact No"
                            id=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                        <div className="Search me-2">
                          <button
                            type="button"
                            onClick={getDataAll}
                            className="btn btn-search"
                          >
                            <img src={search1} className="search" alt="" />
                          </button>
                        </div>

                        <div className="Search-1">
                          <button
                            type="button"
                            onClick={() => {
                              setSearch("");
                              setReset(!reset);
                            }}
                            className="btn btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-line mt-3"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                    <Table striped bordered hover responsive center>
                      <thead>
                        <tr className="">
                          {/* {visible.col0 && (
                            <th className="check round-check">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                ></label>
                              </div>
                            </th>
                          )} */}
                          {visible.col1 && <th className="sr">Sr. No.</th>}
                          {visible.col2 && (
                            <th className="tax-name">First Name</th>
                          )}
                          {visible.col3 && (
                            <th className="tax-name">Last Name</th>
                          )}
                          {visible.col4 && <th className="tax-name">Email</th>}
                          {visible.col5 && (
                            <th className="tax-name">Contact No</th>
                          )}

                          {visible.col12 && <th className="tax-name">View</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.data?.map((d, index) => {
                          return (
                            <tr className="" key={index}>
                              {/* {visible.col0 && (
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={d?.id}
                                      name="perselected"
                                      checked={selectAllChecked.includes(d?.id)}
                                      onChange={handleChange}
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    ></label>
                                  </div>
                                </td>
                              )} */}
                              {visible.col1 && <td>{++index}.</td>}
                              {visible.col2 && <td>{d?.first_name}</td>}
                              {visible.col3 && <td>{d?.last_name}</td>}
                              {visible.col4 && <td>{d?.email}</td>}
                              {visible.col5 && <td>{d?.contact_no}</td>}

                              {visible.col12 && (
                                <td>
                                  <div className="add me-3">
                                    <Link
                                      type="button"
                                      className="btn btn-add pe-3"
                                    >
                                      <div onClick={() => handleshow(d)}>
                                        view
                                      </div>
                                    </Link>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {show ? (
        <TableOffCanvance
          handleClose={handleClose3}
          setShow={setshow}
          show={show}
          data={singledata}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
