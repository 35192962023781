import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router";

import Cookies from "js-cookie";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
library.add(fas);

function Header(props) {
  const {
    setUserData,
    setUsertype,
    setSignin,
    usertype,
    isSidebarOpen,
    setSidebarOpen,
    toggleSidebar,
    getData,
  } = useContext(Context);

  const navigate = useNavigate();

  const LogOut = async () => {
    Cookies.remove("hiclousia_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  return (
    <>
      <section className="header">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Navbar.Brand>
            <div className="header-text">
              <div className="bars" onClick={toggleSidebar}>
                <FaBars />
              </div>
              <h1>{props.title}</h1>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="header-icons">
                {/* <div className="search-area icon">
                  <InputGroup className="input-group-text">
                    <Form.Control
                      placeholder="Search Something here.."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">
                      <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                    </InputGroup.Text>
                  </InputGroup>
                </div> */}

                {/* <div className="header_icons icon  position-relative">
                  <FontAwesomeIcon icon="fa-solid fa-comments" className="" />
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    5+
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </div> */}

                {/* <div className="header_icons icon position-relative">
                  <FontAwesomeIcon icon="fa-solid fa-bell" />
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    5+
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </div> */}

                {/* <div className="header_icons icon">
                  <FontAwesomeIcon icon="fa-solid fa-clock" />
                </div> */}

                <div className="icon dropdown ">
                  <div
                    className="text-center dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p className="mb-0 logout_content">Super Admin</p>
                    {/* <p className="super_admintxt">Super Admin</p> */}
                  </div>

                  <ul class="dropdown-menu">
                    {/* <li>
                      <a class="dropdown-item" href="#">
                        <FontAwesomeIcon
                          icon="fa-solid fa-user me-4"
                          className="text-primary"
                        />
                        <span className="ms-3">Profile</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <FontAwesomeIcon
                          icon="fa-solid fa-message"
                          className="text-success"
                        />
                        <span className="ms-3">Inbox</span>
                      </a>
                    </li> */}
                    <li>
                      <a class="dropdown-item" href="#" onClick={LogOut}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-right-from-bracket"
                          className="text-danger"
                        />
                        <span className="ms-3">Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </section>
    </>
  );
}

export default Header;
