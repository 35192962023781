import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  const [category, setCategory] = useState({});
  const [subcategory, setSubcategory] = useState([]);
  const [days, setDays] = useState([]);
  const [formData, setFormData] = useState({
    price: "",
    job_posts: "",
    talent_pool_category_id: "",
    talent_pool_subcategory_id: "",
    days_id: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const handleChange = async (e) => {
    if (
      e?.name == "talent_pool_category_id" ||
      e?.name == "talent_pool_subcategory_id" ||
      e?.name == "days_id"
    ) {
      // await setFormData({ ...formData, [e.name]: e });
      if (e?.name == "talent_pool_category_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          talent_pool_subcategory_id: "",
        });
        getSubCategoryData(e.value);
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".talentpoolplanclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".talentpoolplanclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(
          "/talent_pool/talent_pool_plan",
          formData
        );

        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d*\.?\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const getCategoryData = async () => {
    const res = await getData("/getalltalentpoolcategory");

    const option = [];
    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "talent_pool_category_id",
          label: data.name,
        });
      });
    }
    setCategory(option);
  };

  const getSubCategoryData = async (id) => {
    const res = await getData(`/getalltalentpoolsubcategory/${id}`);

    // const option = [];
    if (res.success) {
      setSubcategory(res?.data);
      //   res?.data?.map((data) => {
      //     option.push({
      //       value: data.id,
      //       name: "talent_pool_category_id",
      //       label: data.name,
      //     });
      //   });
    }
    // setCategory(option);
  };

  const getDaysData = async () => {
    const res = await getData("/alldays");

    // const option = [];
    if (res?.success) {
      // res?.data?.map((data) => {
      //   option.push({
      //     value: data.id,
      //     name: "days_id",
      //     label: data.name,
      //   });
      // });
      setDays(res?.data);
    }
  };

  useEffect(() => {
    getCategoryData();
    getDaysData();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Talent Pool Subcategory</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Talent Pool Plan</Card.Title>
              <hr />
              <Row>
                <Col md={12}>
                  <Row className="">
                    <Col xxl={12} xl={12} md={10}>
                      <Form
                        onSubmit={handleSubmit}
                        role="form"
                        className="talentpoolplanclass"
                      >
                        <Row>
                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Talent Pool Category
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <Select
                                      name="talent_pool_category_id"
                                      className="input-mandatory"
                                      value={formData?.talent_pool_category_id}
                                      options={category}
                                      onChange={handleChange}
                                    />
                                    <span className="error-message"></span>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Talent Pool Subcategory
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <Select
                                      name="talent_pool_subcategory_id"
                                      className="input-mandatory"
                                      value={
                                        formData?.talent_pool_subcategory_id
                                      }
                                      options={subcategory?.map((data) => ({
                                        value: data?.id,
                                        name: "talent_pool_subcategory_id",
                                        label: data?.name,
                                      }))}
                                      onChange={handleChange}
                                    />
                                    <span className="error-message"></span>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Days
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <Select
                                      name="days_id"
                                      className="input-mandatory"
                                      value={formData?.days_id}
                                      options={days?.map((data) => ({
                                        value: data?.id,
                                        name: "days_id",
                                        label: data?.no,
                                      }))}
                                      onChange={handleChange}
                                    />
                                    <span className="error-message"></span>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Job Posts
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="tel"
                                        name="job_posts"
                                        value={formData.job_posts}
                                        onChange={(e) => {
                                          if (e.target.value >= 1) {
                                            handleChange(e);
                                          } else if (e.target.value == "") {
                                            handleChange(e);
                                          }
                                        }}
                                        onKeyPress={handleKeyPressContact}
                                        placeholder="Job Posts"
                                        id="inputEmail3"
                                        className="input-mandatory"
                                      />
                                    </InputGroup>
                                    <span className="error-message"></span>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Price
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="tel"
                                        name="price"
                                        value={formData.price}
                                        onChange={(e) => {
                                          if (e.target.value >= 1) {
                                            handleChange(e);
                                          } else if (e.target.value == "") {
                                            handleChange(e);
                                          }
                                        }}
                                        onKeyPress={handleKeyPressContact}
                                        placeholder="Price"
                                        id="inputEmail3"
                                        className="input-mandatory"
                                      />
                                    </InputGroup>
                                    <span className="error-message"></span>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-5 pb-3">
                          <div className="d-flex justify-content-center">
                            <Link>
                              <CancelButton
                                name={"cancel"}
                                handleClose={props.handleClose}
                              />
                            </Link>
                            <SaveButton
                              name={"save"}
                              handleSubmit={handleSubmit}
                            />
                          </div>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
