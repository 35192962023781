import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import SideBarIMG from "../assets/HICLOUSIA-logo-for-developer.png";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";

library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  return (
    <>
      <section className="sidebar">
        <div className="top_section">
          <img
            style={{ display: isSidebarOpen ? "block" : "none" }}
            className="logo ms-2"
            src={SideBarIMG}
          />
        </div>
        <ul className="list-unstyled ps-0">
          {/* ***************************************************Dashboard*************************************************************** */}

          <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Item
              eventKey="0"
              className="mb-1"
              onClick={() => handleClick("0")}
            >
              <Accordion.Header>
                <div className=" me-3 scale-in-hor-right">
                  <FontAwesomeIcon
                    icon="fa-solid fa-house"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  className="sidebar_txt"
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                >
                  Dashboard
                </p>

                {/* </button> */}
              </Accordion.Header>
              <Accordion.Body>
                <ul
                  className={`accordion_list list-unstyled fw-normal pb-1 small ${
                    isSidebarOpen ? "arrowshow" : "arrowHide"
                  }`}
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link to="/advanceDashboard" className="text-decoration-none">
                    <li className={isActive("/advanceDashboard")}>
                      <p key={2} className="insideDropdown_list" id={"2"}>
                        Advance Dashboard
                      </p>
                    </li>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            {/* *********************************************************Dashboard Ends*************************************************************** */}

            {/* **************************************************************************Setting************************************************************** */}

            <Accordion.Item
              eventKey="1"
              className="mb-1"
              onClick={() => handleClick("1")}
            >
              <Link to={"/settingContent"} className=" text-decoration-none">
                <Accordion.Header>
                  <div className=" me-3">
                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                    <b></b>
                    <b></b>
                  </div>

                  <p
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                    className="sidebar_txt"
                  >
                    Settings
                  </p>
                </Accordion.Header>
              </Link>

              {/* <ul
                className=" list-unstyled fw-normal pb-1 small"
                style={{
                  display: isSidebarOpen ? "block" : "none",
                }}
              >
                <Link
                  to="/masters/country"
                  className=" text-decoration-none"
                >
                  <li className={isActive("/masters/country")}>
                    <p className="insideDropdown_list">Country</p>
                  </li>
                </Link>

                <Link
                  to="/masters/state"
                  className="text-decoration-none"
                >
                  <li className={isActive("/masters/state")}>
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      State
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/city"
                  className="text-decoration-none"
                >
                  <li className={isActive("/masters/city")}>
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      City
                    </p>
                  </li>
                </Link>
                <Link
                  to="/masters/job_type"
                  className="text-decoration-none  "
                >
                  <li className={isActive("/masters/job_type")}>
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Job Type
                    </p>
                  </li>
                </Link>
                <Link
                  to="/masters/job_title"
                  className="text-decoration-none"
                >
                  <li className={isActive("/masters/job_title")}>
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Job Title
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/industry"
                  className="text-decoration-none"
                >
                  <li className={isActive("/masters/industry")}>
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Industry
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/sector"
                  className="text-decoration-none"
                >
                  <li className={isActive("/masters/sector")}>
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Sector
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/career_level"
                  className="text-decoration-none"
                >
                  <li className={isActive("/masters/career_level")}>
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Career Level
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/qualification"
                  className="text-decoration-none"
                >
                  <li
                    className={isActive("/masters/qualification")}
                  >

                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Qualification
                    </p>
                  </li>
                </Link>
                <li
                  className={isActive("/masters/education_type")}
                >
                  <Link to="/masters/country" className=" text-decoration-none">
                    <li className={isActive("/masters/country")}>
                      <p className="insideDropdown_list">Country</p>
                    </li>
                  </Link>

                  <Link to="/masters/state" className="text-decoration-none">
                    <li className={isActive("/masters/state")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        State
                      </p>
                    </li>
                  </Link>

                  <Link to="/masters/city" className="text-decoration-none">
                    <li className={isActive("/masters/city")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        City
                      </p>
                    </li>
                  </Link>
                  <Link
                    to="/masters/job_type"
                    className="text-decoration-none  "
                  >
                    <li className={isActive("/masters/job_type")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Job Type
                      </p>
                    </li>
                  </Link>
                  <Link
                    to="/masters/job_title"
                    className="text-decoration-none"
                  >
                    <li className={isActive("/masters/job_title")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Job Title
                      </p>
                    </li>
                  </Link>

                  <Link to="/masters/industry" className="text-decoration-none">
                    <li className={isActive("/masters/industry")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Industry
                      </p>
                    </li>
                  </Link>

                  <Link to="/masters/sector" className="text-decoration-none">
                    <li className={isActive("/masters/sector")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Sector
                      </p>
                    </li>
                  </Link>

                  <Link
                    to="/masters/career_level"
                    className="text-decoration-none"
                  >
                    <li className={isActive("/masters/career_level")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Career Level
                      </p>
                    </li>
                  </Link>

                  <Link
                    to="/masters/qualification"
                    className="text-decoration-none"
                  >
                    <li className={isActive("/masters/qualification")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Qualification
                      </p>
                    </li>
                  </Link>
                  <li className={isActive("/masters/education_type")}>
                    <Link
                      to="/masters/education_type"
                      className="text-decoration-none rounded "
                    >
                      Nationality
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/experience_in_year"
                  className="text-decoration-none"
                >
                  <li
                    className={isActive("/masters/experience_in_year")}>
                    <p
                      className="insideDropdown_list">
                      Experience In Years
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/blog_type"
                  className={`text-decoration-none ${isActive("/masters/blog_type")}`}
                >
                  <li className={isActive("/masters/blog_type")}>
                    <p
                      className="insideDropdown_list">
                      Blog Type
                    </p>
                  </li>
                </Link>

                <Link
                  to="/masters/blog"
                  className="text-decoration-none " >
                  <li className={isActive("/masters/blog")}>
                    <p
                      className="insideDropdown_list">
                      Blog
                    </p>
                  </li>
                </Link>

                <li className={isActive("/masters/language")}>
                  <Link
                    to="/masters/language"
                    className="text-decoration-none "
                  >
                    <li className={isActive("/masters/nationality")}>
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Nationality
                      </p>
                    </li>
                  </Link>

                    >
                      Language
                    </p>
                  </Link>
                </li>
                <li className={isActive("/masters/shift")}>
                  <Link
                    to="/masters/shift"
                    className="text-decoration-none "
                  >
                    <li className={isActive("/masters/experience_in_year")}>
                      <p className="insideDropdown_list">Experice In Years</p>
                    </li>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/degree_type")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/blog_type"
                    className={`text-decoration-none ${isActive(
                      "/masters/blog_type"
                    )}`}
                  >
                    <li className={isActive("/masters/blog_type")}>
                      <p className="insideDropdown_list">Blog Type</p>
                    </li>
                  </Link>
                </li>

                  <Link to="/masters/blog" className="text-decoration-none ">
                    <li
                      // className={isActive("/masters/blog")}
                      className={
                        isOpen
                          ? headerText === "/masters/blog"
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p className="insideDropdown_list">Blog</p>
                    </li>
                  </Link>
                </li>

                  <li className={isActive("/masters/language")}>
                    <Link
                      to="/masters/language"
                      className="text-decoration-none "
                    >
                      <p className="insideDropdown_list">Language</p>
                    </Link>
                  </li>
                  <li className={isActive("/masters/shift")}>
                    <Link to="/masters/shift" className="text-decoration-none ">
                      <p className="insideDropdown_list">Shift</p>
                    </Link>
                  </li>

                  <li
                    className={
                      isOpen
                        ? headerText.includes("/masters/degree_type")
                          ? "active block"
                          : "none"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/university_type"
                    className="text-decoration-none "
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      University Type
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText === "/masters/university"
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/university"
                    className="text-decoration-none "
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      University
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/experience_type")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/experience_type"
                    className="text-decoration-none "
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Experience Type
                    </p>
                  </Link>
                </li>


                <li
                  className={
                    isOpen
                      ? headerText == "/masters/skill_type"
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/skill_type"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Skill Type
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText == "/masters/skills"
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/skills"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Skills Name
                    </p>
                  </Link>
                </li>



                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/client_review")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/client_review"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Client Review
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/social_links")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/social_links"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Social Link
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/company")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/company"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Company List
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/project_type")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/project_type"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Project Type
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/role_type")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/role_type"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Role Type
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/role_subtype")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/role_subtype"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Role SubType
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/roles")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/roles"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Roles
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/round")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/round"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Round
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/authority")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/authority"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Authority Name
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/league")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/league"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      League
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/descipline")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/descipline"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Descipline
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/skill_league")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/skill_league"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Skill League
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText == "/masters/skills_master"
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/skills_master"
                    className="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Skills Masters
                    </p>
                  </Link>
                </li>

                <li
                  className={
                    isOpen
                      ? headerText.includes("/masters/education")
                        ? "active block"
                        : "none"
                      : "none"
                  }
                >
                  <Link
                    to="/masters/education"
                    class="text-decoration-none rounded"
                  >
                    <p
                      className="insideDropdown_list"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Education Master
                    </p>
                  </Link>
                </li>
              </ul> */}
              {/* </div> */}
            </Accordion.Item>
            {/* </li> */}
            {/* **************************************************************************Setting Ends************************************************************** */}

            {/* *************************************************************Talent Pool*************************************************************** */}
            <Accordion.Item
              eventKey="2"
              className="mb-1"
              onClick={() => handleClick("2")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-gear"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  className="sidebar_txt"
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                >
                  Talent Pool
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    to="/talent_pool/talent_pool_category"
                    className=" text-decoration-none  "
                    // className="text-decoration-none rounded "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                              "/talent_pool/talent_pool_category"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Talent Pool Category
                      </p>
                    </li>
                  </Link>

                  <Link
                    to="/talent_pool/talent_pool_subcategory"
                    className=" text-decoration-none "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                              "/talent_pool/talent_pool_subcategory"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Talent Pool Subcategory
                      </p>
                    </li>
                  </Link>

                  <Link
                    to="/talent_pool/talent_pool_plan"
                    className="text-decoration-none"
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/talent_pool/talent_pool_plan")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Talent Pool Plan
                      </p>
                    </li>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            {/* *************************************************************Talent Pool Ends*************************************************************** */}

            {/* *********************************************************Recruiters************************************************************** */}
            <Accordion.Item
              eventKey="3"
              className="mb-1"
              onClick={() => handleClick("3")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-users"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                  className="sidebar_txt"
                >
                  Customer Management
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    to="/recruiters/recruiters_list"
                    className="text-decoration-none "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/recruiters/recruiters_list")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                      // className={isActive("/masters/recruiters_list")}
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Recruiters List
                      </p>
                    </li>
                  </Link>

                  <li
                    className={
                      isOpen
                        ? headerText.includes("/recruiters/contact_us_list")
                          ? "active block"
                          : "none"
                        : "none"
                    }
                    // className={isActive("/masters/contact_us_list")}
                  >
                    <Link
                      to="/recruiters/contact_us_list"
                      className="text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Contact Us List</p>
                    </Link>
                  </li>

                  <li
                    className={
                      isOpen
                        ? headerText.includes("/jobseeker/jobseeker_list")
                          ? "active block"
                          : "none"
                        : "none"
                    }
                  >
                    <Link
                      to="/jobseeker/jobseeker_list"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Job Seeker List</p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* ********************************************************* Pool Subscriber************************************************************** */}

            <Accordion.Item
              eventKey="4"
              className="mb-1"
              onClick={() => handleClick("4")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-bell"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                  className="sidebar_txt"
                >
                  Pool Subscriber
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    to="/recruiters/pool_subscriber"
                    className="text-decoration-none "
                  >
                    <li
                      // className={isActive("/masters/pool_subscriber")}
                      className={
                        isOpen
                          ? headerText.includes("/recruiters/pool_subscriber")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Subscriber List
                      </p>
                    </li>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            {/* ********************************************************* Pool Subscriber ends************************************************************** */}




          </Accordion>
          {/* *********************************************************Recruiters Ends************************************************************** */}

          {/* <Accordion defaultActiveKey="0" className="mb-1">
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-city" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Client</p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className=" text-white me-3">
                <FontAwesomeIcon icon="fa-solid fa-user-group" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}> HR</p>
            </Accordion.Header>

          
            <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <a
                    href="#"
                    className="text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Employee
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>Leaves</p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Shift Roster
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Attendance
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Holiday
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Designation
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Department
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Appreciation
                    </p>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
           
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className=" text-white me-3">
                <FontAwesomeIcon icon="fa-solid fa-briefcase" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Work</p>
            </Accordion.Header>

            <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Contract
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Project
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}> Task </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Timesheet
                    </p>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
          
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="text-white me-3">
                <FontAwesomeIcon icon="fa-solid fa-dollar-sign" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Finance</p>
            </Accordion.Header>
            
            <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Proposal
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Estimates
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Invoice
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Payments{" "}
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Credit Note{" "}
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Expenses{" "}
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Bank Account{" "}
                    </p>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
           
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-basket-shopping" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Product</p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-cart-shopping" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Order</p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-headset" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Tickets</p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-calendar-day" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Events</p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-message" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Messages</p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-clipboard" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>
                Notices Board
              </p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-file-lines" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>
                Knowledge Base
              </p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-display" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Assets</p>
            </Accordion.Header>
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="text-white me-3">
                <FontAwesomeIcon icon="fa-solid fa-wallet" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Payroll</p>
            </Accordion.Header>
            
            <Accordion.Body>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Payroll
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Employee Salary
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Reports
                    </p>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
           
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="text-white me-3">
                <FontAwesomeIcon icon="fa-solid fa-mobile" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Recruit</p>
            </Accordion.Header>
           
            <Accordion.Body>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Dashboard
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}> Skill</p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>Jobs</p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Job Application
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Interview Schedule
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Offer Letters
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Candidate Database
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Reports
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Career Site
                    </p>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
           
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="text-white me-3">
                <FontAwesomeIcon icon="fa-solid fa-video" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Zoom</p>
            </Accordion.Header>
          
            <Accordion.Body>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Zoom Meeting
                    </p>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
         
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="text-white me-3">
                <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Reports</p>
            </Accordion.Header>
           
            <Accordion.Body>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Task Report
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Task Log Report
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Finance Report
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Income Vs Expense
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Leave Report
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Attendance Report
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Expense Report
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Lead Report
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      {" "}
                      Sales Report
                    </p>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
         
          </Accordion> */}

          {/* <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Header>
              <div className="me-3">
                <FontAwesomeIcon icon="fa-solid fa-gear" />
              </div>
              <p style={{ display: isOpen ? "block" : "none" }}>Setting</p>
            </Accordion.Header>
          </Accordion> */}
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
