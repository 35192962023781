import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);
  //   const { id } = useParams();

  const id = props.show;

  const [universitytype, setUniversityType] = useState([]);
  const [data, setData] = useState();
  const [league, setLeague] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const [formData, setFormData] = useState({
    university_type_id: "",
    name: "",
    league_id: "",
    country_id: "",
    state_id: "",
    city_id: "",
    hic_no: "",
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = async (e) => {
    if (
      e?.name === "university_type_id" ||
      e?.name === "country_id" ||
      e?.name === "state_id" ||
      e?.name === "city_id" ||
      e?.name === "league_id"
    ) {
      if (e?.name === "country_id") {
        const res = await getData(`/allstate/${e?.value}`);
        setState(await Select2Data(res.data, "state_id"));
        setFormData({ ...formData, [e.name]: e, state_id: "", city_id: "" });
      } else if (e?.name === "state_id") {
        const res = await getData(`/allcity/${e?.value}`);
        setCity(await Select2Data(res.data, "city_id"));
        setFormData({ ...formData, [e.name]: e, city_id: "" });
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".universityclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".universityclass",
    });
    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(`/masters/university/${id}`, formData);
        const success = await response.success;
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/university/${id}`);

    setData(response);
    setFormData(response?.data);

    // await getDropDown("country", response?.data?.country_id);
    // await getDropDown("state", response?.data?.state_id);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const getAllData = async () => {
    try {
      {
        const response = await getData("/alluniversitytype");
        setUniversityType(
          await Select2Data(response?.data, "university_type_id")
        );
      }
      {
        const response = await getData("/allleague");
        setLeague(await Select2Data(response?.data, "league_id"));
      }
      {
        const response = await getData("/allcountry");
        setCountry(await Select2Data(response?.data, "country_id"));
      }
    } catch (error) {
      console.error("Error fetching university types:", error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const getDropDown = async (name, id) => {
    try {
      if (name === "country") {
        const res = await getData(`/allstate/${id}`);
        setState(await Select2Data(res.data, "state_id"));
      } else if (name === "state") {
        const res = await getData(`/allcity/${id}`);
        setCity(await Select2Data(res.data, "city_id"));
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      // Handle errors, e.g., set a default state for dropdowns or show an error message
    }
  };

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
        
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit University</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="universityclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      University Type
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="university_type_id"
                                        className="custom-select"
                                        value={formData?.university_type_id}
                                        options={universitytype}
                                        onChange={handleChange}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      University
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="University "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Hic No
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="tel"
                                            name="hic_no"
                                            value={formData.hic_no}
                                            onChange={handleChange}
                                            onKeyPress={handleKeyPressContact}
                                            placeholder="Hic No "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                             
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={props.handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit University
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="universityclass">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Country</Form.Label>

                    <Select
                      name="country_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.country_id}
                      options={country}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>State</Form.Label>

                    <Select
                      name="state_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.state_id}
                      options={state}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>City</Form.Label>

                    <Select
                      name="city_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.city_id}
                      options={city}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>University Type</Form.Label>

                    <Select
                      name="university_type_id"
                      className="custom-select"
                      value={formData?.university_type_id}
                      options={universitytype}
                      onChange={handleChange}
                    />

                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>University</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData?.name}
                          onChange={handleChange}
                          placeholder="University "
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Hic No</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="tel"
                          name="hic_no"
                          value={formData.hic_no}
                          onChange={handleChange}
                          onKeyPress={handleKeyPressContact}
                          placeholder="Hic No "
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>League</Form.Label>

                    <Select
                      name="league_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.league_id}
                      options={league}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
