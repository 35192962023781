import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const [skills, setSkills] = useState([]);
  const [formData, setFormData] = useState({
    skill_type_id: "",
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "skill_type_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".skillsclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".skillsclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/masters/skills", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d*\.?\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const getallSkilltype = async () => {
    const response = await getData("/allskilltype");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "skill_type_id",
          label: data.name,
        });
      });
    }
    setSkills(option);
  };

  useEffect(() => {
    getallSkilltype();
  }, []);

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>

        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Skills</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="skillsclass"
                          >
                            <Row>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Skill Type {" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="skill_type_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData.skill_type_id}
                                        options={skills}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Skills
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Skills "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>

                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={props.handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Skills
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="skillsclass">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Skill Type</Form.Label>

                    <Select
                      name="skill_type_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.skill_type_id}
                      options={skills}
                    />

                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Skills</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Skills "
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
